
.starterButtons{
    background-color: var(--color_quaternary);
    transition: .5s;
}
#startButton:hover {
    background-color: #4b9a46;
    color: #fff;
}



.sudoku-board {
    display: grid;
    grid-template-rows: repeat(9, 1fr); /* Creates 9 rows */
    /* grid-gap: 2px; Adjust based on your design */
    max-width: 40rem;
    margin: auto;
    background-color: #3333333a;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.sudoku-row {
    display: flex; /* This will layout the child cells in a row */
    justify-content: center;
}

.sudoku-cell {
    width: 4rem; /* Or adjust based on your design */
    height: 4rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #333;
    border-left: 2px solid #666;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}

.sudoku-input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 20px;
    background-color: transparent;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.bold-border-right {
    border-right: 4px solid #666; 
}
.bold-border-bottom {
    border-bottom: 4px solid #666;
}

.locked {
    font-weight: bold; 
    cursor:not-allowed; 
}
.concrete {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 20px;
    background-color: rgba(79, 66, 66, 0.929); 
    color: rgb(255, 255, 255) !important; 
    font-family: 'Arial', sans-serif; 
}
@keyframes flashError {
    0% { color: initial;}
    50% { color : red; background-color: black;}
    100% { color: initial;}
}

.error-cell {
    animation: flashError 1s ease-out infinite;
}

